import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import CustomerCardUtil,{ICustomerCardDataObj} from './customerCardUtil';

export default defineComponent({
    name:'CustomerCard',
    title:'客户',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        provide('uploadOwner', proxy);
        const validateSimpleName = (rule: any, value: any, callback: any) => {
            if (value == null || value.trim()=='') {
                callback(new Error('请输入简称'))
            } else if (!/^[A-Z]+$/.test(dataObj.form.simpleName)){
                callback(new Error('请输入最多5个大写英文字母'))
            }else{
                callback()
            }
        }
        let dataObj:ICustomerCardDataObj=reactive<ICustomerCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    customerHdgs:{},
                    customerAccount:{},
                    customerDelivery:{},
                },
                details:['customerHdgs','customerAccount','customerDelivery'],
                modelPath: utils.OrderProviderApi.buildUrl('/customer')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                name: [utils.UtilPub.commonValidRule(proxy.$t('customer.form.name_rule'))],
                simpleName: [utils.UtilPub.commonValidRule('请输入简称'),{ validator: validateSimpleName, trigger: 'blur' }],
                countryId: [utils.UtilPub.commonValidRule('请选择国家')],
                contact: [utils.UtilPub.commonValidRule('请输入联系号码')],
                email: [utils.UtilPub.commonValidRule('请输入邮箱地址'),{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}],
                companyName: [utils.UtilPub.commonValidRule('请输入公司名称')],
                contactAddress: [utils.UtilPub.commonValidRule('请输入联系地址')],
            },
            otherParams:{
                disableFlag2:false,//有些字段在编辑的时候不能修改（国家、简称）
                totalCustomerAccount:0,//目前总的客户账号数量，生成客户账号时用
                countryData:[],//国家下拉数据集
                activeTabName:'customerAccount',
                customerAccount:{
                    accountType:0,//账号类型
                    dialogVisible:false//新增账号信息弹出框显示/影藏控制字段
                },
                amountInfo:{
                    dialogVisible:false,//账号变动历史弹出框显示/影藏控制字段
                    accountType:'',//账户类别[oa,tt,ttOa]
                    bz:'',//账户类别[元,$]
                    changeList:[]//账户金额变动历史
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CustomerCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
                dataObj.refMap.get('customerAccount').getTbInst().setMaxTbHeight(300);
                dataObj.refMap.get('customerDelivery').getTbInst().setMaxTbHeight(300);
                dataObj.refMap.get('customerHdgs').getTbInst().setMaxTbHeight(300);
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'accountType'==params.comboId){
                    return [{value:0,label:'整车'},{value:1,label:'售后'}]
                }
                if(params && 'F_STATUS'==params.comboId){
                    return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                }
                if(params && 'countryId'==params.comboId){
                    return dataObj.otherParams.countryData
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (options:any) => {
                return {
                    detailParam: {
                        gridTitle:options.title,
                        readOnly:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: options.detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/customer/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.form.id,
                    fileSize:2,
                    limitNum:1,
                    listType:'picture-card',
                    accept:'.jpg,.jpeg,.png',
                    showFileList:true,
                    selfFileTemplate:false,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('F_STATUS'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.otherParams.totalCustomerAccount=res.totalCustomerAccount;
            if('/load'==addOrLoad){
                dataObj.otherParams.disableFlag2=true;//编辑的时候，让国家、简称不能修改
            }
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{
            //todo:如果明细中有cascader，而且每行的cascader数据可能不同，那么可以在这里再次请求后台，重新根据条件构造该行cascader数据
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            if('customerHdgs'==options.detailType){ }
            if('customerAccount'==options.detailType){
                options.row.F_STATUS_NAME = dataObj.refMap.get('F_STATUS').states.selected.currentLabel;//设置下拉select选取的文本值到行中
                dataObj.refMap.get('customerAccount').setRowData(options.row, options.index);
            }
            if('customerDelivery'==options.detailType){ }
            return true;
        }

        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法(要么不填，只要填写了，那行的每项都必填)
            let customerDelivery=dataObj.refMap.get('customerDelivery').getDetailData();
            if(customerDelivery.length>0){
                let result=customerDelivery.findIndex((row:any,index:number)=>{
                    let existNullItem=!row.F_NAME || !row.F_CONTACT || !row.F_ADDRESS;
                    if(existNullItem){
                        dataObj.otherParams.activeTabName='customerDelivery';
                        utils.Tools.warning({message:'第'+(index+1)+'行内容不全'});
                    }
                    return existNullItem;
                })
                return result==-1;
            }
            let customerHdgs=dataObj.refMap.get('customerHdgs').getDetailData();
            if(customerHdgs.length>0){
                let result=customerHdgs.findIndex((row:any,index:number)=>{
                    let existNullItem=!row.F_NAME || !row.F_CONTACT || !row.F_ADDRESS || !row.F_LOAD_ADDRESS;
                    if(existNullItem){
                        dataObj.otherParams.activeTabName='customerHdgs';
                        utils.Tools.warning({message:'第'+(index+1)+'行内容不全'});
                    }
                    return existNullItem;
                })
                return result==-1;
            }
            return true;
        }
        //确定新增账号事件
        const sureAddAccountHandler=()=>{
            dataObj.otherParams.totalCustomerAccount=dataObj.otherParams.totalCustomerAccount+1;
            let accountName='21'+dataObj.utilInst.prefixZero(dataObj.otherParams.totalCustomerAccount,4);
            let accountType=dataObj.otherParams.customerAccount.accountType;
            let row={F_TYPE_NAME:accountType==0?'整车':'售后',F_TYPE:accountType,F_CODE:accountName,F_STATUS:0,F_STATUS_NAME:'启用'}
            dataObj.refMap.get('customerAccount').addRow(row);
            dataObj.otherParams.customerAccount.dialogVisible = false;
        }
        //文件上传之后
        const afterResult=(res:any)=>{
            // if(res.result)dataObj.form.headPic=res.uploadFiles[0].path;
        }
        //删除某行账号之后
        const afterDelAccount=(options:any)=>{
            let tbData=dataObj.refMap.get(options.detailType).getDetailData();
            //所有新增的行
            tbData=tbData.filter((item:any)=>!item['NOTSHOWDEL']);
            //还原没有新增账号的时候，后台的totalCustomerAccount（末尾还减去1的原因是：tbData.length已经被删除了一行了，但是totalCustomerAccount还未减1）
            dataObj.otherParams.totalCustomerAccount=dataObj.otherParams.totalCustomerAccount-tbData.length-1;
            //循环所有新增行，重新赋值
            tbData.forEach((item:any)=>{
                dataObj.otherParams.totalCustomerAccount=dataObj.otherParams.totalCustomerAccount+1;
                item['F_CODE']='21'+dataObj.utilInst.prefixZero(dataObj.otherParams.totalCustomerAccount,4);
            })
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,cardDetailParam,buildUploadInitParams,beganEdit,beforeEndEdit,beforeOpen,beforeSaveHandler
            ,sureAddAccountHandler,afterResult,afterDelAccount
        }
    }
});